// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/



:root {
  /** primary **/
  --ion-color-primary: #702826;
  --ion-color-primary-rgb: 112,40,38;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255,255,255;
  --ion-color-primary-shade: #632321;
  --ion-color-primary-tint: #7e3e3c;
  /** secondary **/
  --ion-color-secondary: #026d9a;
  --ion-color-secondary-rgb: 2,109,154;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255,255,255;
  --ion-color-secondary-shade: #026088;
  --ion-color-secondary-tint: #1b7ca4;
  /** tertiary **/
  --ion-color-tertiary: #7044ff;
  --ion-color-tertiary-rgb: 112, 68, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #633ce0;
  --ion-color-tertiary-tint: #7e57ff;
  /** success **/
  --ion-color-success: #218c74;
  --ion-color-success-rgb: 33,140,116;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255,255,255;
  --ion-color-success-shade: #1d7b66;
  --ion-color-success-tint: #379882;
  /** warning **/
  --ion-color-warning: #ffce00;
  --ion-color-warning-rgb: 255, 206, 0;
  --ion-color-warning-contrast: #ffffff;
  --ion-color-warning-contrast-rgb: 255, 255, 255;
  --ion-color-warning-shade: #e0b500;
  --ion-color-warning-tint: #ffd31a;
  /** danger **/
  --ion-color-danger: #acbc5e;
  --ion-color-danger-rgb: 245, 61, 61;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #d33939;
  --ion-color-danger-tint: #f25454;
  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 34, 34;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;
  /** medium **/
  --ion-color-medium: #f7f1e3;
  --ion-color-medium-rgb: 255, 255, 255;
  --ion-color-medium-contrast: #413f3c;
  --ion-color-medium-contrast-rgb: 132,129,122;
  --ion-color-medium-shade: #86888f;
  --ion-color-medium-tint: #a2a4ab;
  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 244, 244;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;
  /** contabilidade **/
  --ion-color-contabilidade: #FDC666;
  --ion-color-contabilidade-rgb: 253,198,102;
  --ion-color-contabilidade-contrast: #000000;
  --ion-color-contabilidade-contrast-rgb: 0,0,0;
  --ion-color-contabilidade-shade: #dfae5a;
  --ion-color-contabilidade-tint: #fdcc75;

  .ion-color-contabilidade {
      --ion-color-base: var(--ion-color-contabilidade) !important;
      --ion-color-base-rgb: var(--ion-color-contabilidade-rgb) !important;
      --ion-color-contrast: var(--ion-color-contabilidade-contrast) !important;
      --ion-color-contrast-rgb: var(--ion-color-contabilidade-contrast-rgb) !important;
      --ion-color-shade: var(--ion-color-contabilidade-shade) !important;
      --ion-color-tint: var(--ion-color-contabilidade-tint) !important;
  }

  --ion-color-cinza-escuro: #727272;
  --ion-color-cinza-escuro-rgb: 114,114,114;
  --ion-color-cinza-escuro-contrast: #ffffff;
  --ion-color-cinza-escuro-contrast-rgb: 255,255,255;
  --ion-color-cinza-escuro-shade: #646464;
  --ion-color-cinza-escuro-tint: #808080;

  .ion-color-cinza-escuro {
      --ion-color-base: var(--ion-color-cinza-escuro);
      --ion-color-base-rgb: var(--ion-color-cinza-escuro-rgb);
      --ion-color-contrast: var(--ion-color-cinza-escuro-contrast);
      --ion-color-contrast-rgb: var(--ion-color-cinza-escuro-contrast-rgb);
      --ion-color-shade: var(--ion-color-cinza-escuro-shade);
      --ion-color-tint: var(--ion-color-cinza-escuro-tint);
  }

  --ion-color-vermelho: #c0392b;
  --ion-color-vermelho-rgb: 232,65,24;
  --ion-color-vermelho-contrast: #ffffff;
  --ion-color-vermelho-contrast-rgb: 255,255,255;
  --ion-color-vermelho-shade: #cc3915;
  --ion-color-vermelho-tint: #ea542f;


  .ion-color-vermelho {
      --ion-color-base: var(--ion-color-vermelho);
      --ion-color-base-rgb: var(--ion-color-vermelho-rgb);
      --ion-color-contrast: var(--ion-color-vermelho-contrast);
      --ion-color-contrast-rgb: var(--ion-color-vermelho-contrast-rgb);
      --ion-color-shade: var(--ion-color-vermelho-shade);
      --ion-color-tint: var(--ion-color-vermelho-tint);
  }

  --ion-color-prata: #f5f6fa;
  --ion-color-prata-rgb: 245,246,250;
  --ion-color-prata-contrast: #000000;
  --ion-color-prata-contrast-rgb: 0,0,0;
  --ion-color-prata-shade: #d8d8dc;
  --ion-color-prata-tint: #f6f7fb;

  .ion-color-prata {
      --ion-color-base: var(--ion-color-prata);
      --ion-color-base-rgb: var(--ion-color-prata-rgb);
      --ion-color-contrast: var(--ion-color-prata-contrast);
      --ion-color-contrast-rgb: var(--ion-color-prata-contrast-rgb);
      --ion-color-shade: var(--ion-color-prata-shade);
      --ion-color-tint: var(--ion-color-prata-tint);
  }

  --ion-color-verde: #00ae2c;
  --ion-color-verde-rgb: 0,174,44;
  --ion-color-verde-contrast: #ffffff;
  --ion-color-verde-contrast-rgb: 255,255,255;
  --ion-color-verde-shade: #009927;
  --ion-color-verde-tint: #1ab641;


.ion-color-verde {
  --ion-color-base: var(--ion-color-verde);
  --ion-color-base-rgb: var(--ion-color-verde-rgb);
  --ion-color-contrast: var(--ion-color-verde-contrast);
  --ion-color-contrast-rgb: var(--ion-color-verde-contrast-rgb);
  --ion-color-shade: var(--ion-color-verde-shade);
  --ion-color-tint: var(--ion-color-verde-tint);
}
  /** economia **/
  --ion-color-economia: #E6E96E;
  --ion-color-economia-rgb: 230,233,110;
  --ion-color-economia-contrast: #000000;
  --ion-color-economia-contrast-rgb: 0,0,0;
  --ion-color-economia-shade: #cacd61;
  --ion-color-economia-tint: #e9eb7d;

  .ion-color-economia {
      --ion-color-base: var(--ion-color-economia) !important;
      --ion-color-base-rgb: var(--ion-color-economia-rgb) !important;
      --ion-color-contrast: var(--ion-color-economia-contrast) !important;
      --ion-color-contrast-rgb: var(--ion-color-economia-contrast-rgb) !important;
      --ion-color-shade: var(--ion-color-economia-shade) !important;
      --ion-color-tint: var(--ion-color-economia-tint) !important;
  }
  /** engenharia **/
  --ion-color-engenharia: #C4D66B;
  --ion-color-engenharia-rgb: 196,214,107;
  --ion-color-engenharia-contrast: #000000;
  --ion-color-engenharia-contrast-rgb: 0,0,0;
  --ion-color-engenharia-shade: #acbc5e;
  --ion-color-engenharia-tint: #cada7a;

  .ion-color-engenharia {
      --ion-color-base: var(--ion-color-engenharia) !important;
      --ion-color-base-rgb: var(--ion-color-engenharia-rgb) !important;
      --ion-color-contrast: var(--ion-color-engenharia-contrast) !important;
      --ion-color-contrast-rgb: var(--ion-color-engenharia-contrast-rgb) !important;
      --ion-color-shade: var(--ion-color-engenharia-shade) !important;
      --ion-color-tint: var(--ion-color-engenharia-tint) !important;
  }
  /** arquitetura **/
  --ion-color-arquitetura: #79D2DA;
  --ion-color-arquitetura-rgb: 121,210,218;
  --ion-color-arquitetura-contrast: #000000;
  --ion-color-arquitetura-contrast-rgb: 0,0,0;
  --ion-color-arquitetura-shade: #6ab9c0;
  --ion-color-arquitetura-tint: #86d7de;

  .ion-color-arquitetura {
      --ion-color-base: var(--ion-color-arquitetura) !important;
      --ion-color-base-rgb: var(--ion-color-arquitetura-rgb) !important;
      --ion-color-contrast: var(--ion-color-arquitetura-contrast) !important;
      --ion-color-contrast-rgb: var(--ion-color-arquitetura-contrast-rgb) !important;
      --ion-color-shade: var(--ion-color-arquitetura-shade) !important;
      --ion-color-tint: var(--ion-color-arquitetura-tint) !important;
  }
  /** politicas **/
  --ion-color-politicas: #36B9A8;
  --ion-color-politicas-rgb: 54,185,168;
  --ion-color-politicas-contrast: #000000;
  --ion-color-politicas-contrast-rgb: 0,0,0;
  --ion-color-politicas-shade: #30a394;
  --ion-color-politicas-tint: #4ac0b1;

  .ion-color-politicas {
      --ion-color-base: var(--ion-color-politicas) !important;
      --ion-color-base-rgb: var(--ion-color-politicas-rgb) !important;
      --ion-color-contrast: var(--ion-color-politicas-contrast) !important;
      --ion-color-contrast-rgb: var(--ion-color-politicas-contrast-rgb) !important;
      --ion-color-shade: var(--ion-color-politicas-shade) !important;
      --ion-color-tint: var(--ion-color-politicas-tint) !important;
  }
  /** naturais **/
  --ion-color-naturais: #37A5CC;
  --ion-color-naturais-rgb: 55,165,204;
  --ion-color-naturais-contrast: #000000;
  --ion-color-naturais-contrast-rgb: 0,0,0;
  --ion-color-naturais-shade: #3091b4;
  --ion-color-naturais-tint: #4baed1;

  .ion-color-naturais {
      --ion-color-base: var(--ion-color-naturais) !important;
      --ion-color-base-rgb: var(--ion-color-naturais-rgb) !important;
      --ion-color-contrast: var(--ion-color-naturais-contrast) !important;
      --ion-color-contrast-rgb: var(--ion-color-naturais-contrast-rgb) !important;
      --ion-color-shade: var(--ion-color-naturais-shade) !important;
      --ion-color-tint: var(--ion-color-naturais-tint) !important;
  }
  /** saude **/
  --ion-color-saude: #396CAD;
  --ion-color-saude-rgb: 57,108,173;
  --ion-color-saude-contrast: #ffffff;
  --ion-color-saude-contrast-rgb: 255,255,255;
  --ion-color-saude-shade: #325f98;
  --ion-color-saude-tint: #4d7bb5;

  .ion-color-saude {
      --ion-color-base: var(--ion-color-saude) !important;
      --ion-color-base-rgb: var(--ion-color-saude-rgb) !important;
      --ion-color-contrast: var(--ion-color-saude-contrast) !important;
      --ion-color-contrast-rgb: var(--ion-color-saude-contrast-rgb) !important;
      --ion-color-shade: var(--ion-color-saude-shade) !important;
      --ion-color-tint: var(--ion-color-saude-tint) !important;
  }
  /** datascie **/
  --ion-color-datascie: #6a6a6a;
  --ion-color-datascie-rgb: 106,106,106;
  --ion-color-datascie-contrast: #ffffff;
  --ion-color-datascie-contrast-rgb: 255,255,255;
  --ion-color-datascie-shade: #5d5d5d;
  --ion-color-datascie-tint: #797979;

  .ion-color-politicas {
      --ion-color-base: var(--ion-color-datascie) !important;
      --ion-color-base-rgb: var(--ion-color-datascie-rgb) !important;
      --ion-color-contrast: var(--ion-color-datascie-contrast) !important;
      --ion-color-contrast-rgb: var(--ion-color-datascie-contrast-rgb) !important;
      --ion-color-shade: var(--ion-color-datascie-shade) !important;
      --ion-color-tint: var(--ion-color-datascie-tint) !important;
  }
  /** secretaria **/
  --ion-color-secretaria: #c0c0c0;
  --ion-color-secretaria-rgb: 192,192,192;
  --ion-color-secretaria-contrast: #000000;
  --ion-color-secretaria-contrast-rgb: 0,0,0;
  --ion-color-secretaria-shade: #a9a9a9;
  --ion-color-secretaria-tint: #c6c6c6;

  .ion-color-politicas {
      --ion-color-base: var(--ion-color-secretaria) !important;
      --ion-color-base-rgb: var(--ion-color-secretaria-rgb) !important;
      --ion-color-contrast: var(--ion-color-secretaria-contrast) !important;
      --ion-color-contrast-rgb: var(--ion-color-secretaria-contrast-rgb) !important;
      --ion-color-shade: var(--ion-color-secretaria-shade) !important;
      --ion-color-tint: var(--ion-color-secretaria-tint) !important;
  }

  p{color: #1e2023;
font-size: 13px;
}
}


